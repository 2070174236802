import {
  Container,
  useMediaQuery,
  Card,
  CardContent,
  Typography,
  Box,
} from "@mui/material";
import { useGetHealthProductByIdQuery } from "../../services/Landing.service";
import Filters from "./components/Filters/Filters";
import QuoteContainer from "./components/QuoteContainer/QuoteContainer";
import QuoteCount from "./components/QuoteCount/QuoteCount";
import ToolBar from "./components/Toolbar/Toolbar";
import ViewPlanPopup from "./components/QuoteCard/components/ViewPlanPopup/ViewPlanPopup";
import SelectedCompare from "./components/SelectedCompare/SelectedCompare";

const Quotes = () => {
  const { isEnquiryLoading } = useGetHealthProductByIdQuery();
  const isMobile = useMediaQuery("(max-width:768px)");

  if (isEnquiryLoading) {
    return <></>;
  }

  return (
    <Box
      sx={{
        padding: isMobile ? "20px" : "36px",
        // boxShadow: 3,
        // border: "1px solid black",
        borderRadius: 2,
        width: isMobile ? "95%" : "85%",
        minHeight: "800px",
        margin: "auto",
        marginTop: "1%",
      }}
    >
      {/* Styled Header */}
      {/* <Typography
        variant="h4"
        sx={{
          fontWeight: "bold",
          textAlign: "center",
          fontSize: isMobile ? "24px" : "36px",
          marginTop: "-20px",
        }}
      >
        <span style={{ fontSize: "70%", color: "#666" }}>Explore </span>
        <span
          style={{
            fontSize: "inherit",
            position: "relative",
          }}
        >
          health plan’s
          <span
            style={{
              display: "block",
              width: "100%",
              height: "4px",
              backgroundColor: "orange",
              position: "absolute",
              bottom: "-6px",
              left: 0,
            }}
          />
        </span>
      </Typography> */}

      <ToolBar />
      <Filters />
      <QuoteCount />
      <QuoteContainer />
      <ViewPlanPopup />
      <SelectedCompare />
    </Box>
  );
};

export default Quotes;
